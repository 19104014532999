import React, { useState } from 'react';
import './FAQ.css';

function FAQ() {

  const faqData = [
    {
      question: "Quelle est la date du mariage ?",
      answer: "Le mariage aura lieu le Samedi 26 Juillet 2025 à partir de 14h30 pour la messe."
    },
    {
      question: "Où se déroulera la cérémonie ?",
      answer: "La cérémonie religieuse se tiendra à l'Église de Pornic."
    },
    {
      question: "Y aura-t-il un parking disponible ?",
      answer: "Oui, un parking est disponible à proximité de la salle de réception."
    },
    {
      question: "Puis-je apporter un plus-un ?",
      answer: "Merci de confirmer votre présence afin de nous indiquer si vous souhaitez venir accompagné."
    },
    {
      question: "Y aura-t-il des options végétariennes disponibles lors du dîner ?",
      answer: "Oui, des options végétariennes seront disponibles. Merci de nous informer de vos préférences lors de votre RSVP."
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="home-div">
      {/* Sections précédentes... */}

      {/* Section FAQ */}
      <div id="faq" className="section-div">
        <h3 className="section-title">FAQ</h3>
        <div className="faq-container">
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <div 
                className="faq-question" 
                onClick={() => toggleFAQ(index)}
              >
                {item.question}
                <span className="faq-toggle">
                  {activeIndex === index ? '-' : '+'}
                </span>
              </div>
              {activeIndex === index && (
                <div className="faq-answer">
                  {item.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}

export default FAQ;