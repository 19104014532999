// App.js
import React from 'react';

import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import Headers from './components/Header/Header';
import './App.css';

function App() {
  return (
    <div className='default'>
      <Headers />
      <div className='content'>
        <Home />
        <Footer />
      </div>
    </div>
  );
}

export default App;