import React from 'react';
import './Home.css';
import FAQ from './FAQ';
import { motion, useInView } from 'framer-motion';

// Nouveau composant Section
const Section = ({ content, sectionVariants, id }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, {
    once: false,
    amount: 0.3,
    rootMargin: '-100px 0px',
  });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={sectionVariants}
    >
      {content}
    </motion.div>
  );
};

// Nouveau composant ProgrammeItem pour animer chaque élément individuellement
const ProgrammeItem = ({ item, index }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, {
    once: false,
    amount: 0.3,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: item.reverse ? 100 : -100 }}
      animate={isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: item.reverse ? 100 : -100 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      className={`programme-row ${item.reverse ? 'reverse' : ''}`}
    >
      <div className="programme-image">
      <img src={`${process.env.PUBLIC_URL}${item.image}`} alt={item.title} />
      </div>
      <div className="programme-text">
        <h4>{item.title}</h4>
        <p>{item.description}</p>
        <p><strong>Heure :</strong> {item.time}</p>
        <p><strong>Lieu :</strong> {item.location}</p>
        <a 
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item.location)}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="gmaps-button"
        >
          Voir sur Google Maps
        </a>
      </div>
    </motion.div>
  );
};

function Home() {
  // const placeholderImage = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Pornic_-_Ch%C3%A2teau_-_01.jpg/800px-Pornic_-_Ch%C3%A2teau_-_01.jpg';

  // Simplifier les variantes d'animation
  const sectionVariants = {
    hidden: { 
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="home-div">
      {/* Titre principal avec animation initiale */}
      <motion.div
        className="title"
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>Solène et Guillaume</h1>
        <h2>vous souhaitent la bienvenue</h2>
      </motion.div>

      {/* Sections avec animations au défilement */}
      {[
        {
          id: 'notre-histoire',
          content: (
            <div className="section-div">
              <h3 className="section-title">Notre petite histoire</h3>
              <div className="section-text">
                Solène et Guillaume se sont rencontrés il y a cinq ans. Depuis, ils ont partagé de nombreux moments inoubliables ensemble, des aventures à travers le monde aux soirées tranquilles à la maison. Leur amour a grandi et s'est renforcé au fil des années, les menant à ce jour spécial où ils ont décidé de s'unir pour la vie.
              </div>
            </div>
          ),
        },
        {
          id: 'info-pratiques',
          content: (
            <div id="info-pratiques" className="section-div">
              <h3 className="section-title">Infos Pratiques</h3>
              <div className="tiles-container">
                <div className="tile">
                  <span className="material-symbols-outlined">favorite</span> 
                  <h3>Qui</h3>
                  <p className="small-text">VOUS, car on vous aime fort et nous serions honorés de vous avoir à nos côtés pour ce jour si spécial</p>
                </div>
                <div className="tile">
                  <span className="material-symbols-outlined">event</span> 
                  <h3>Quand</h3>
                  <p className="small-text">Le Samedi 26 Juillet 2025 à partir de 14h30 pour la messe</p>
                </div>
                <div className="tile">
                  <span className="material-symbols-outlined">church</span>
                  <h3>Où</h3>
                  <p className="small-text">Entre Pornic et Frossay, Pays de Retz, à proximité de Nantes</p>
                </div>
              </div>
            </div>
          ),
        },
        {
          id: 'programme',
          content: (
            <div id="programme" className="section-div">
              <h3 className="section-title">Programme</h3>
              <div className="programme-container">
                {[            {
                    title: "Mariage civile &Découverte de Pornic",
                    description: `Pour les lève-tôt, rejoignez-nous dès le matin pour notre union civile dans les anciennes écuries. A noter: Nombre de places limité à la mairie

Puis, flânez dans le vieux port ou empruntez le sentier des douaniers pour admirer les cabanes de pêcheurs emblématiques de la côte d'Opale. Profitez-en pour savourer la fameuse glace de La Fraiseraie (un must !) ou une crêpe en terrasse. 

Pour les plus curieux, une visite de la thalasso, de la fabrique des galettes Saint-Michel ou de la faïencerie de Pornic s'impose !`,
                    time: "Samedi 26 Juillet - 9h30",
                    location: "Relais Saint-Gilles, Pornic",
                    image: "/images/mariage-mairie-cabane-pecheur.jpg",
                    reverse: true
                  },
                  {
                    title: "Messe de mariage à Eglise Saint gilles de Pornic",
                    description: "Un moment de recueillement et de joie partagée, où chacun est invité à prier et se réjouir avec nous. La messe sera célébrée par Monseigneur Olivier Ribadeau Dumas, parrain de la mariée. Préparez vos mouchoirs et vos plus beaux sourires ! A noter: Note : L’église se trouve en haut du village. Plusieurs parkings sont à disposition, à différentes distances ; merci d’anticiper pour les personnes à mobilité réduite, voir infos pratiques",
                    time: "Samedi 26 Juillet - 14h30",
                    location: "Église Saint Gilles, Pornic",
                    image: "/images/mariage-eglise-village-pornic.jpg",
                    reverse: false
                  },
                  {
                    title: "Cocktail + Dîner + Party",
                    description: `WE DID IT ! Venez célébrer notre union et faire la fête !

A noter: il faut compter 20min en voiture pour rejoindre Frossay. Pour les non-motorisés, nous avons réservé une navette au départ de la gare de Pornic, voir infos pratiques.`,
                    time: "Samedi 26 Juillet - 16h30",
                    location: "Château de la Rousselière, Frossay",
                    image: "/images/soiree-rousseliere-garden.jpg",
                    reverse: true
                  },
                  {
                    title: "Brunch",
                    description: "Pour prolonger les festivités et profitez de vous un peu plus longtemps, nous serions ravis de vous retrouvez le lendemain autour d'un café (much needed) et quelques bonnes choses",
                    time: "Dimanche 27 Juillet - 11h30",
                    location: "Château de la Rousselière, Frossay",
                    image: 'images/brunch-rousseliere.jpg', // Keep placeholder or specify another image
                    reverse: false
                  },
                ].map((item, index) => (
                  <ProgrammeItem 
                    key={index} 
                    item={item} 
                    index={index}
                  />
                ))}
              </div>
            </div>
          ),
        },
        {
          id: 'rsvp',
          content: (
            <div id="rsvp" className="section-div">
              <h3 className="section-title">RSVP</h3>
              <div className="section-text">
                <p>Placeholder content for RSVP section.</p>
              </div>
            </div>
          ),
        },
        {
          id: 'liste',
          content: (
            <div id="liste" className="section-div">
              <h3 className="section-title">Liste</h3>
              <div className="section-text">
                <p>Placeholder content for Liste section.</p>
              </div>
            </div>
          ),
        },
        {
          id: 'faq',
          content: (
            <FAQ />
          ),
        },

      ].map((section) => (
        <Section
          key={section.id}
          id={section.id}
          content={section.content}
          sectionVariants={sectionVariants}
        />
      ))}
    </div>
  );
}

export default Home;