import React from 'react';

function Footer() {
  return (
    <footer style={{ textAlign: 'center', width: '100%' }}>
      <p>&copy; 2024 Guillaume & Solène's Wedding. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
